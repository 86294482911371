<template>
  <div class="setgame" ref="fullpanel">
    <div class="setgame_box" ref="insidepanel">
      <div class="setgame_title">Setup Game ID</div>
      <div class="setgame_container">
        <div
          class="setgame_container:single"
          v-for="(t, index) in listid"
          :key="index"
        >
          <div
            :class="
              t.home_away == 'H'
                ? 'setgame_container:single_val_red'
                : 'setgame_container:single_val_green'
            "
            @click="
              setGame(t.liveId, t.hometeamname, t.awayteamname, t.match_id)
            "
          >
            {{ t.name }}
          </div>
        </div>
      </div>
    </div>
    <Menu />
  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/general/nav/menu.vue";
export default {
  data() {
    return {
      listid: "",
    };
  },
  components: {
    Menu,
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    setGame(t, hometeamname, awayteamname, matchId) {
      this.$store.commit("setpostactive", false);
      this.$store.commit("setliveactive", true);
      this.$store.commit("setIdMatch", matchId);
      this.$store.commit("changeidgame", t);
      this.$store.commit("players", []);
      this.$store.commit("sethometeamname", hometeamname);
      this.$store.commit("setawayteamname", awayteamname);
      this.$store.commit("setOnline");
      this.$store.commit("alarms", []);
      this.$store.commit("alarmsThresolds", []);
      // Legge ed imposta allarmi
      this.getAlarms(t);
      this.$store.commit("setIsActivePlayersControl", false); // Spengo i controlli sui giocatori nel live
      // Imposta la tabella su Realtime
      this.$store.commit("tableMode", "Realtime");
      // Route su animazione
      this.$router.push("/field");
      // location.reload();
      //        this.$store.commit('setSnackbar', {color: 'error', text: "PROVA"});
    },
    getIds() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/lives4user/" + this.userid,
          this.headerConfig
        )
        .then((response) => {
          this.listid = response.data;
        });
    },
    getAlarms(t) {
      axios
        .get(process.env.VUE_APP_API_URL + "/kpilive/" + t)
        .then((response) => {
          if (response.data) {
            // Salva il primo Kpi nell'ipotesi che non sia un allarme
            this.$store.commit(
              "setGraphParameter",
              response.data[0].short_desc
            );

            let data = response.data
              .filter((x) => x.isAlarm == "1")
              .map((e) => {
                return e.short_desc;
              });
            this.$store.commit("alarms", data);

            data = response.data
              .filter((x) => x.isAlarm == "1")
              .map((e) => {
                return e.threshold_min;
              });
            this.$store.commit("alarmsThresolds", data);

            let batteryFound = false;
            let circleFound = false;
            for (const kpi of response.data) {
              if (!batteryFound && kpi.show_as == "B") {
                this.$store.commit("showAsBattery", kpi.short_desc);
                this.$store.commit("batteryThresold", kpi.threshold_min);
                batteryFound = true;
              } else if (!circleFound && kpi.show_as == "C") {
                this.$store.commit("showAsCircle", kpi.short_desc);
                this.$store.commit("circleThresold", kpi.threshold_min);
                circleFound = true;
              }
            }
          }
        })
        .catch((err) => {
          axios
            .get(process.env.VUE_APP_API_URL + "/kpi", this.headerConfig)
            .then((response) => {
              if (response.data) {
                // Salva il primo Kpi nell'ipotesi che non sia un allarme
                this.$store.commit(
                  "setGraphParameter",
                  response.data[0].short_desc
                );

                let data = response.data
                  .filter((x) => x.isAlarm == "1")
                  .map((e) => {
                    return e.short_desc;
                  });
                this.$store.commit("alarms", data);

                data = response.data
                  .filter((x) => x.isAlarm == "1")
                  .map((e) => {
                    return e.threshold_min;
                  });
                this.$store.commit("alarmsThresolds", data);

                let batteryFound = false;
                let circleFound = false;
                for (const kpi of response.data) {
                  if (!batteryFound && kpi.show_as == "B") {
                    this.$store.commit("showAsBattery", kpi.short_desc);
                    this.$store.commit("batteryThresold", kpi.threshold_min);
                    batteryFound = true;
                  } else if (!circleFound && kpi.show_as == "C") {
                    this.$store.commit("showAsCircle", kpi.short_desc);
                    this.$store.commit("circleThresold", kpi.threshold_min);
                    circleFound = true;
                  }
                }
              }
            });
        });

      // Aggancia Allarmi customizzati
      axios
        .get(process.env.VUE_APP_API_URL + "/playersalarms/" + t)
        .then((response) => {
          if (response.data) {
            let thArray = [];
            for (const [key, value] of Object.entries(response.data)) {
              thArray[key] = value;
            }
            this.$store.commit("customThresolds", thArray);
          }
        })
        .catch((err) => {
          console.log("Error during playersalarms call " + err);
        });
    },
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    } else {
      this.getIds();
    }
  },
};
</script>

<style lang="scss" scoped>
.setgame {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 1000;
  &_box {
    color: var(--color);
    width: auto;
    display: inline-block;
    padding: 30px;
    background-image: var(--background-image);
    border: var(--border);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    position: absolute;
    &\:close {
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 650px;
    &\:single {
      width: (100%/3);
      padding: 20px;
      &_val {
        &_red {
          color: $red;
          font-weight: bold;
          font-size: 30px;
        }
        &_green {
          color: $green;
          font-weight: bold;
          font-size: 30px;
        }
      }
      &_valActive {
      }
    }
  }
  &_text {
    width: 650px;
    text-align: center;
  }
}
</style>
