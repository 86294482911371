var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "fullpanel", staticClass: "setgame" },
    [
      _c("div", { ref: "insidepanel", staticClass: "setgame_box" }, [
        _c("div", { staticClass: "setgame_title" }, [_vm._v("Setup Game ID")]),
        _c(
          "div",
          { staticClass: "setgame_container" },
          _vm._l(_vm.listid, function(t, index) {
            return _c(
              "div",
              { key: index, staticClass: "setgame_container:single" },
              [
                _c(
                  "div",
                  {
                    class:
                      t.home_away == "H"
                        ? "setgame_container:single_val_red"
                        : "setgame_container:single_val_green",
                    on: {
                      click: function($event) {
                        return _vm.setGame(
                          t.liveId,
                          t.hometeamname,
                          t.awayteamname,
                          t.match_id
                        )
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(t.name) + " ")]
                )
              ]
            )
          }),
          0
        )
      ]),
      _c("Menu")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }